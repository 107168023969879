<template>
  <div>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <v-tabs v-model="messageTab" class="px-6" background-color="transparent">
      <v-tab> {{ $t('chatListTab') }} </v-tab>
    </v-tabs>

    <v-tabs-items v-model="messageTab" class="overflow-visible ml-6 mt-4">
      <v-tab-item :value="0" class="pt-0"> </v-tab-item>
      <PatientMessageChatList
        :is-component-active="isComponentActive"
        :chat-list="chatList"
        :selected-chat-id="selectedChatId"
        :patient="patient"
        :available-participants="availableParticipants"
        @loadChatList="loadPatientChatList"
      ></PatientMessageChatList>
    </v-tabs-items>
  </div>
</template>

<script>
import translationMixin from '@/translationMixin';

import PatientMessageChatList from './PatientMessageChatList.vue';
import accessibility from '@/accessibilityMixin';
import messageService from '@/services/messageService';
import patientService from '@/services/patientService';
import { ConversationParticipantTypes } from '@/components/PatientMonitoring/constants';

export default {
  name: 'PatientMessage',
  components: { PatientMessageChatList },

  mixins: [translationMixin, accessibility],

  props: {
    patientId: {
      type: Number,
      required: false,
      default: null,
    },
    selectedChatId: {
      type: Number,
      required: false,
      default: null,
    },
    isComponentActive: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      messageTab: 0,
      chatList: {},
      patient: {},
      error: null,
      showWaitModal: false,
      availableParticipants: [],
    };
  },

  watch: {
    patientId: async function () {
      await this.loadPatientInfo();
      await this.loadPatientChatList();
    },
  },
  created: function () {
    this.init();
  },

  methods: {
    async init() {
      if (this.patientId != null) {
        this.showWaitModal = true;

        await this.loadPatientInfo();
        await this.loadPatientChatList();
      }
      this.messageTab = 0;
    },

    async loadPatientChatList() {
      this.showWaitModal = false;
      try {
        this.chatList = await messageService.getPatientConversations(this.patientId);
        this.chatList.conversations.forEach((conversation) => {
          const participants = conversation.participants;
          const patientParticipant = participants?.includePatient ? this.patient : null;
          const caregiverParticipants = this.getPatientCaregiversFromIds(this.patient, participants?.caregiverIds);
          conversation.participants = this.getParticipantObjects(patientParticipant, caregiverParticipants);
        });
        this.chatList.conversations.sort((a, b) => {
          if (a.lastUpdate && b.lastUpdate) {
            return new Date(b.lastUpdate) - new Date(a.lastUpdate);
          } else {
            return !a.lastUpdate ? -1 : 1;
          }
        });
      } catch (error) {
        this.error = error;
      }
    },
    async loadPatientInfo() {
      this.showWaitModal = false;

      try {
        this.patient = await patientService.getPatientById(this.patientId);
        const availableCaregivers = this.patient.caregiverInfo.filter((c) => c.caregiverEmail);
        this.availableParticipants = this.getParticipantObjects(this.patient, availableCaregivers).filter(
          (p) => p.isActive
        );
      } catch (error) {
        this.error = error;
      }
    },
    getPatientCaregiversFromIds(patient, caregiverIds) {
      const caregivers = [];
      if (caregiverIds && caregiverIds.length) {
        caregiverIds.forEach((caregiverId) => {
          let caregiver = patient.caregiverInfo.find((info) => info.caregiverId === caregiverId);
          caregivers.push(caregiver);
        });
      }
      return caregivers;
    },
    getParticipantObjects(patient, caregivers) {
      const participantObjects = [];

      if (patient) {
        const patientObject = {
          id: patient.id,
          type: ConversationParticipantTypes.PATIENT,
          firstName: patient.firstName,
          lastName: patient.lastName,
          isActive: patient.active,
        };
        participantObjects.push(patientObject);
      }

      const caregiverObjects = [];

      caregivers?.forEach((caregiver) => {
        caregiverObjects.push({
          id: caregiver?.caregiverId,
          type: ConversationParticipantTypes.CAREGIVER,
          firstName: caregiver?.caregiverFirstName,
          lastName: caregiver?.caregiverLastName,
          isActive: caregiver?.relationConfirmed,
        });
      });
      caregiverObjects.sort((a, b) => (a.firstName < b.firstName ? -1 : a.firstName > b.firstName ? 1 : 0));
      participantObjects.push(...caregiverObjects);
      return participantObjects;
    },
  },
};
</script>

<style scoped></style>
